import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid,
    TablePagination, LinearProgress, Paper, Tooltip,
    Typography,} from "@material-ui/core";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import PaginacionTab from '../layout/PaginacionTab'
const useStyles = makeStyles((theme) => ({}));

const CamapanaN = () => {
    const classes = useStyles();
    const idusu = localStorage.getItem("UsuId");
    const history = useHistory();
    const source = axios.CancelToken.source(); 
    const limite = 50; 
    const [galeria, setGaleria] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);
    const [listDatos,setListDatos]=useState(0);

    useEffect(() => {
        setGaleria([]);
        
        consultarImagenes();
        return ()=> {
            source.cancel();
        }  
    }, []);

    const consultarImagenes = () => {
        const data = qs.stringify({       
            usuario: idusu,
            idDispositivo: "987gyubjhkkjh",
        });
        const url = "campania/list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setGaleria([]);
                setMensaje("Sin fotografías.");
            } 
            else 
            {   if (response.length>limite) {
       
                    const auxTam = response.length;
                    if(auxTam%limite===0)
                    {
                        const pagina=Math.trunc(auxTam/limite)
                        setTotalPaginas(pagina)   
                    }
                    else
                    {   let pagina=Math.trunc(auxTam/limite)
                        pagina=pagina+1;
                        setTotalPaginas(pagina)
                    }
                    setNumPagina(0) 
                    setGaleria(response.slice(0,limite))                   
                }
                else{
                    setTotalPaginas(1)
                    setNumPagina(0)        
                    setGaleria(response)          
                }  

                setListDatos(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };
 
    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {   setGaleria(listDatos.slice(0,limite))   
        }
        else
        {   setGaleria(listDatos.slice(newPage * limite, newPage * limite + limite))   
        }
        setNumPagina(newPage)
    }

    function generarFooter(){
        return(
        <TablePagination  style={{padding:0}}
          rowsPerPageOptions={false}
          colSpan={3} rowsPerPage={limite}
          count={listDatos.length} page={numPagina}
          lang='es'  onPageChange={onChangePagina}
          ActionsComponent={PaginacionTab}
          labelDisplayedRows={
            ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
            }
          }
        />)
    }

    const listarGaleria =
        galeria.length > 0 ? (
            <Galeria
                galeria={galeria}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );
 
    const principal = () => {
        return (
        <Grid container spacing={1}  justifyContent="center" >
           
            <Grid item xs={12}>
                 
            </Grid>
 
            <Grid item xs={10}>
       
                {cargando ? (
                    <Box pl={1} pt={5}>
                        <LinearProgress/>
                    </Box>
                ) : (
                    listarGaleria
                )}
            </Grid>
        </Grid>
        );
    };
    const paginacion = totalPaginas>1 ? generarFooter() : null
    return( 
    <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
        <Box display={"flex"} >
        {paginacion}
        </Box>
        <Box display={"flex"}>
        {principal()}
        </Box>
        
    </div>);
};

export default CamapanaN;
