import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem,
     Select, TextField, FormControlLabel, Checkbox,} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import { obtenDispo,obtenIdUsu} from '../funciones/datosLocal';
import {useHistory} from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles((theme) => ({}));

const ModalNvoEditRecorrido = ({abrirModal, setAbrirModal, latitud, longitud, actualiza, registro,titulo}) => {

    const classes = useStyles();
    const usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const auxDispV =obtenDispo()
    const idusu = obtenIdUsu();
    const history = useHistory();
    const tipoNacion=process.env.REACT_APP_Nvl 
    const source = axios.CancelToken.source();
    const [selectedDate, handleDateChange] = useState(fecha);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [nvoRegistro, setNvoRegistro] = useState({ Estado:'', Municipio: '', Seccion: '', Tipo: '',
        Latitud: latitud, Longitud: longitud, Fecha: '', Direccion: '', Observacion: '', Repetido:false,
        cantidad:1, manual:0,ManualCheck:false
    });
    const {Estado, Municipio, Seccion, Tipo, Longitud, Latitud, manual, Direccion, Observacion,Repetido, cantidad,ManualCheck} = nvoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false); 

    useEffect(() => {                                       

        if (listMun.length === 0) {
            recuperarMunicipios()
        }
            
        if (listSecc.length === 0) {
            getSecciones(1)
        }    
        
        if (listTipo.length === 0) {
            recuperarTipo()
        }

        return ()=> {
            source.cancel();
        }  

    }, []);  

    const recuperarTipo = () => {
        const data = qs.stringify({
            Usuario: idusu, idDispositivo: auxDispV
        });
        const url = "recorrido-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListTipo(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getSecciones = (auxTipo) => {
        const data = qs.stringify({
            usuario: idusu, idDispositivo:auxDispV,
        });
        const url = "lugar-seccion-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                if (registro && auxTipo===1) {
                    
                    const seccFilt = response.filter((auxSeccion) => auxSeccion.MuniId === registro.MuniId)                    
                    setAuxListSecc(seccFilt)
                }
                setListSecc(response)    
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const recuperarMunicipios = () => {
        const data = qs.stringify({
            usuario: idusu, estado: process.env.REACT_APP_EDO,
            tipo: usuTipo, idDispositivo:auxDispV,
        });
        const url = "lugar-municipio-tipo";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListMun(response)
                if (registro) {
                    const { EdoId,  MuniId, SeccId, 
                    TipoId,  Direcc, Obsrv,
                    Cantidad}=registro
                    const filtro=response.filter((muni)=>muni.Id === MuniId)                                   
                    setNvoRegistro({...nvoRegistro,
                        Estado:EdoId, Municipio: filtro[0],
                        Seccion: SeccId, 
                        Tipo: TipoId,                                          
                        Direccion: Direcc,
                        Repetido:registro.Rep===1?true:false,
                        Observacion: Obsrv,
                        cantidad:Cantidad,
                    })
                }
                
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
            let seccFilt = listSecc.filter((auxSeccion) => auxSeccion.MuniId === e.Id)
            setAuxListSecc(seccFilt)
            setNvoRegistro({
                ...nvoRegistro,
                Municipio: e,
                Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNvoRegistro({
                ...nvoRegistro,
                Municipio: "", Seccion: ""
            })
            setAuxListSecc([])
        }
    }

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNvoRegistro({
                ...nvoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNvoRegistro({
                ...nvoRegistro,
                Seccion: ""
            })
        }
    }

    const onChange = e => {
        setNvoRegistro({
            ...nvoRegistro,
            [e.target.name]: e.target.value
        })
    }

    const onChangeNumero = e => {
        let expNopermitida= new RegExp('[A-Za-z$@#_&-+.()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[0-9$]');             
        if (  e.target.value.length<=2 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegistro({ ...nvoRegistro,[e.target.name] : e.target.value })                
        }		
    }

    const getNvoRecorrido = () => {        

        const data = qs.stringify({
            Usuario: idusu,
            Edo: tipoNacion==="muni"? process.env.REACT_APP_EDO:Estado,
            Muni: Municipio.Id,
            Secc: Seccion,
            fecha: moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',
            latitud: Latitud,
            longitud: Longitud,
            Tipo: Tipo,
            Direccion: Direccion,
            Observ: Observacion,
            Manual:0,
            AntLat:0.0,
            AntLon:0.0,
            ManualDir:"",
            Cantidad:cantidad,
            idDispositivo:auxDispV
        });

        const url = "recorrido/v4/new";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Recorrido agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-recorrido${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const getEditRecorrido = () => {        

        const data = qs.stringify({      
            Llave:registro.Id,
            Usuario:idusu,
            Edo:tipoNacion==="muni"? process.env.REACT_APP_EDO:Estado,
            Muni:Municipio.Id,
            Secc:Seccion,
            Repetido:Repetido?1:0,
            Direccion:Direccion.trim(),
            Observ:Observacion.trim(),
            Cantidad:cantidad,
            idDispositivo:auxDispV
        });

        const url = "recorrido/v3/edit";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Recorrido editado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `edit-recorrido${registro.Id}`
                })
                actualiza()
                setAbrirModal(false)
            } else {               
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `edit-recorrido${registro.Id}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                              
            mandaAlerta('No se lleno el campo '+descripcion,"warning") 
        }
        return(respuesta);
    }

    function validaCantidad(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo>=1 ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, la Cantidad minima debe ser 1',"warning") 
            }
        }      
       
        return respuesta;
    }

  /*   const onChangeCheck = () => {
        setNvoRegistro({...nvoRegistro,ManualCheck:!ManualCheck} )
    }; */
    
    const onChangeRepe =() =>
    {  
        setNvoRegistro({ ...nvoRegistro,Repetido:!Repetido })        
    };

    function guardarDatos(e) {
        e.preventDefault()
        if ( !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección")  && 
            !verificaNulos(Tipo,"Tipo")  && validaCantidad(cantidad,"Cantidad",1) 
            && !verificaNulos(Direccion,"Dirección") && !verificaNulos(Observacion,"Observación")) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            if (registro) {
                getEditRecorrido()
            }
            else{
                getNvoRecorrido()    
            }
                   
        }  
    }

    return (
    <Modal
        titulo={titulo} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
           {/* tipoNacion!=="muni"? <Grid item xs={5}>
                <FormControl size="small">
                    <InputLabel htmlFor="Estado">Estado</InputLabel>
                    <Select native value={Estado} disabled fullWidth                        
                        inputProps={{name: 'Estado', id: 'Estado',}}
                        onChange={onChange}
                    >
                        <option value={process.env.REACT_APP_EDO}>Puebla</option>
                    </Select>
                </FormControl>
            </Grid> 
            :null */}
            <Grid item xs={7}>
                {listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvoPerfil" size='small' fullWidth disabled={registro?true:false}
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                label="Municipio" variant="outlined"/>
                        )}
                    />
                    : <LinearProgress/>}
            </Grid>
            <Grid item xs={4}>
                {auxListSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {auxListSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                                }
                            )}
                        </Select>
                    </FormControl>
                    : <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}} 
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            <MenuItem size='small' value={''}>
                                <em></em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            </Grid>
            <Grid item xs={4}>
                {listTipo.length !== 0 ?
                <FormControl size="small" disabled={registro?true:false}>
                    <InputLabel htmlFor="Tipo">Tipo</InputLabel>
                    <Select native value={Tipo} fullWidth onChange={onChange}
                        inputProps={{name: 'Tipo', id: 'Tipo',}} 
                    >
                        {listTipo.map(element => {
                            return <option key={element.Id} value={element.Id}>
                                {element.Nom}</option>
                        })}
                    </Select>
                </FormControl>
                : null
                }
            </Grid>
            <Grid item xs={3}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha" disabled={registro?true:false}
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="2017-03-14" maxDate={new Date()}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
             
            </Grid>
            <Grid item xs={2} style={{marginTop:.5+'rem'}}>
                <TextField id="cantidad" name="cantidad" label="Cantidad" fullWidth size="small"
                    value={cantidad} onChange={onChangeNumero} variant="outlined"
                    type="number"
                    />
            </Grid>
            <Grid item xs={12} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
               {/* !registro?                     
                    <FormControlLabel  style={{}}
                        label="Escribir dirección" size={'small'}
                        control={<Checkbox color="primary" checked={ManualCheck} name={"ManualCheck"}/>}                
                        labelPlacement="end"  onChange={onChangeCheck}
                    />
                :null}
                {registro || ManualCheck ===true ?
                    
                :null */}
                <TextField id="Direccion" name="Direccion" label="Dirección" fullWidth size="small"
                        value={Direccion} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    rows={4} value={Observacion}
                />
            </Grid>
            {registro?
            <Grid>                
                <FormControlLabel  style={{marginLeft:".5rem"}}
                    label="Repetido" size={'small'}
                    control={<Checkbox color="primary" checked={Repetido} name={"fecha"}/>}                
                    labelPlacement="end"  onChange={onChangeRepe}
                />
            </Grid>
            :null}
        </Grid>
    </Modal>);
};

export default ModalNvoEditRecorrido;
