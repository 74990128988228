export function OrdListUsuEst(lista,tipo) {
    console.log(lista);
    switch (tipo) {
        case 1:
            const filtro = lista.filter((usuario)=>usuario.Id!==1)
            return filtro
        case 2:
            const filtro2 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2)
            return filtro2
        case 3:
            const filtro3 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3)
            return filtro3    
        case 4:
            const filtro4 = lista.filter((usuario)=>usuario.Id===7)
            return filtro4
        case 5:
            const filtro5 = lista.filter((usuario)=>usuario.Id===7)
            return filtro5
        case 6:
            const filtro6 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==12 )
            return filtro6
        case 7:
            const filtro7 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==7 && usuario.Id!==12  )
            return filtro7
        case 8:
            const filtro8 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==7 && usuario.Id!==8 && usuario.Id!==12 )
            return filtro8
        case 9:
            const filtro9 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==7 && usuario.Id!==8&& usuario.Id!==9  && usuario.Id!==10  &&  usuario.Id!==11 && usuario.Id!==12 && usuario.Id!==12 )
            return filtro9
        case 10:
            const filtro10 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==7 && usuario.Id!==8&& usuario.Id!==9 && usuario.Id!==10 && usuario.Id!==12 )
            return filtro10
        case 11:
            const filtro210 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==6 && usuario.Id!==7 && usuario.Id!==8&& usuario.Id!==9 && usuario.Id!==10 && usuario.Id!==11 && usuario.Id!==12 )
            return filtro210
        case 12:
            const filtro12 = lista.filter((usuario)=>usuario.Id!==1 && usuario.Id!==2 && usuario.Id!==3 && usuario.Id!==4 && usuario.Id!==5 && usuario.Id!==11 && usuario.Id!==12)
            return filtro12
            default:
            break;
    }

}


