import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography, IconButton, Tooltip, TablePagination,LinearProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import XLSX from 'xlsx';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";

const useStyles = makeStyles({
    estiloCard:{
      width: '47rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '46.5rem',
      maxHeight: '26.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
 
export default function TablaAvUsr({listUsr}){    
  const classes = useStyles();      
  const tamMax=150
  const idusu = localStorage.getItem("UsuId")?parseInt(localStorage.getItem("UsuId")):0;    
  const auxCol=process.env.REACT_APP_Fondo_Color    
  const [datos,setDatos] = useState([]);       
  const [totalGen,setTotalGen] = useState([]);     
  const [registro, setRegistro] = useState([]) 
  const [espera, setEspera] = useState(false) 
  const [listaExcel, setListaExcel] = useState([]);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  
  useEffect(()=>{    
    if (listUsr && listUsr.length!==0) 
    {
      let total={avTot:0,totSecc:0}
      const arrM=[]
      arrM.push(["#","Avance","Total Sección", "Usuario"])
      listUsr.forEach((element,index) => {
          total.avTot+=element.Avc
          total.totSecc+=element.Sec
          arrM.push([index+1,element.Avc, element.Sec,   element.Usr])
      });
      setDatos(listUsr)  
      setListaExcel(arrM)
      numeroPag(listUsr,tamMax,setTotalPaginas,setNumPagina,setListPagina)    
      setTotalGen(total)
    } 
    else{
      setListaExcel([])
      setDatos([])
      setListPagina([])
    } 
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])          

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb,`${fec.format("YYMMDDHHmmss")} Lista de Avance Seccion  .xlsx` )
  }; 


  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination 
      rowsPerPageOptions={false}  style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>    
        
          <CelTitl index={2} titulo={<span>
            {idusu===1?
           <IconButton onClick={exportarArch} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon style={{color:'white'}} fontSize="small" />
            </Tooltip>
            </IconButton> 
          :null }    
            
            Avance<br/> <strong>
            <NumberFormat value={totalGen.avTot} displayType={'text'}  thousandSeparator={true}/></strong></span> } aline={"center"} />   
                              
          <CelTitl index={2} titulo={ "Sección"   } aline={"center"} />    
    
          <CelTitl index={1} titulo={ "Usuario" } aline={"left"} estilo={{fontSize:"13px"}} />                         
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.MunId === elemnto.MunId ?false:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >             
          {celda("right", {width:"8rem", paddingRight:"4rem"},<NumberFormat value={ elemnto.Avc} displayType={'text'}  thousandSeparator={true}/> ,index+2) }
          {celda("right", {width:"4rem",paddingRight:"1rem"},<NumberFormat value={ elemnto.Sec} displayType={'text'}  thousandSeparator={true}/> ,index+3) }
               
          {celda("left", {width:"40rem"}, elemnto.Usr ,index+1) }     
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= listPagina.length !== 0 ?(TablaEstr(listPagina)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( llamada )                                  
  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                          
  return (

  <Box display={'flex'} flexDirection="row" style={{height: '29rem' }}>
      
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>  
      {paginacion}                      
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {tabla}   
    </Card>

  </Box>                    

  );
}