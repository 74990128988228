import ModalInformativo from '../generales/ModalInformativo'
import {Grid, makeStyles, Box, IconButton, Tooltip, Card, CardContent} from '@material-ui/core';
import {useState} from 'react';
import moment from "moment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

export default function ModalDetalles({
                                          seleccionado,
                                          setModalAbierto,
                                          modalAbierto,
                                          titulo,
                                      }) {

    const [type, setType] = useState(seleccionado.TipoNom);
    const [status, setStatus] = useState(seleccionado.StatusNom);

    const url = 'https://api.whatsapp.com/send?phone=+52' + seleccionado.Tel + '&text=Hola%21%21+Nos+comunicamos+de+AbigailVa';
    const colorTipo = seleccionado.TipoColor;

    const useStyles = makeStyles((theme) => ({
        margin: {
            margin: theme.spacing(1),
        },
        root: {
            '& > svg': {
                fontSize: 18,
                marginRight: theme.spacing(1)
            },
        },
        h3: {
            color: 'rgb(15, 130, 238)',
            margin: 0
        },
        textGray: {
            color: 'gray',
            textAlign: 'justify'
        },
        card: {
            margin: theme.spacing(1),
            color: '#FFF',
            backgroundColor: colorTipo,
            textAlign: 'center',
            marginRight: 0,
            marginLeft: 0
        }
    }));

    const classes = useStyles();

    return (
        <ModalInformativo titulo={titulo}
                          subtitulo={moment.utc(seleccionado.Fecha).format('DD/MM/YYYY HH:mm') + '\n' + seleccionado.MunNom}
                          children={''}
                          modalAbierto={modalAbierto}
                          setModalAbierto={setModalAbierto}
                          tamanio='sm'
                          colorbg={seleccionado.StatusColor}
        >
            <div className={classes.margin}>
                <Grid container spacing={1} alignItems='center' justifyContent='center'>
                    <Grid item md={12}>
                        <p className={classes.textGray}>{seleccionado.Mensaje}</p>
                        <Grid item md={3}>
                            <Grid item>
                                <Card className={classes.card}>
                                    <span>{seleccionado.TipoNom}</span>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                {
                                    seleccionado.Email ?
                                        <>
                                            <Grid item md={1} className={classes.root}>
                                                <MailOutlineIcon color="primary"/>
                                            </Grid>
                                            <Grid item md={5} className={classes.textGray}>
                                                {seleccionado.Email}
                                            </Grid>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    seleccionado.Tel ?
                                        <>
                                            <Grid item md={1} className={classes.root}>
                                                <Tooltip title="Contactar">
                                                    <PhoneIcon color="primary"   onClick={() => window.open(url)}   />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item md={5} className={classes.textGray}>
                                                {seleccionado.Tel}
                                            </Grid>
                                        </>
                                        :
                                        <></>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </ModalInformativo>
    )
}